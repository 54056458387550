.ts-rRelationship {
  .rz-widget-content {
    overflow: inherit !important;
  }

  .filters-badge {
    display: flex;
    flex-wrap: wrap;

    .rz-badge {
      height: 25px;
      display: flex;
      align-items: center;
      margin-bottom: 0.25rem;

      &:not(:first-child) {
        margin-left: 0.25rem;
      }

      .rz-button-icon-only {
        padding: 0 !important;
        margin-left: 0.25rem;
      }
    }
  }

  .intake-nav-btns {
    display: flex;
    justify-content: end;
    align-items: center;
    position: absolute;
    width: 100%;
    right: 1.2rem;
    top: 18px;
  }
}

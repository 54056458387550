.email-view {
  .rz-html-editor,
  .rz-html-editor-toolbar {
    border: none;
  }

  .rz-html-editor-content {
    padding: 0;
  }
}

.timesheet {
  .status-submitted {
    color: var(--rz-secondary);
  }
  .status-billed {
    color: var(--rz-primary);
  }
  .status-paid {
    color: var(--rz-success);
  }
  .status-pending {
    color: var(--rz-success);
  }
  .status-approved {
    color: var(--rz-info);
  }
  .status-unapproved {
    color: var(--rz-warning);
  }
  .price-badge {
    color: var(--rz-success);
    min-width: 56px;
    font-weight: bold;
    font-size: 16px;
  }
  .sessions {
    table {
      thead {
        tr:nth-child(2) {
          display: none;
        }
      }
    }
  }
}

.accounts {
  .balance-badge-success {
    font-size: 1rem;
    line-height: 1.25rem;
    letter-spacing: -0.02;
    color: var(--rz-success);
  }
  .balance-badge-danger {
    font-size: 1rem;
    line-height: 1.25rem;
    letter-spacing: -0.02;
    color: var(--rz-danger);
  }
}

.invoices {
  .status-submitted {
    color: var(--rz-secondary);
  }
  .status-billable {
    color: var(--rz-info);
  }
  .status-paid {
    color: var(--rz-success);
  }
  .status-pending {
    color: var(--rz-text-body1-color);
  }
  .status-billed {
    color: var(--rz-primary);
  }
  .price-badge {
    color: var(--rz-success);
    min-width: 56px;
    font-weight: bold;
    font-size: 16px;
  }
}

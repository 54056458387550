.relationship-intake-step {
  .intake-tutor-grid {
    table {
      thead {
        tr:nth-child(2) {
          display: none;
        }
      }
    }
  }
}

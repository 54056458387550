.admin-page {
  .status-badge {
    padding: 4px;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    width: 160px;
    cursor: pointer;
  }

  .rz-chkbox-label {
    margin: 0 0.4rem 0 0.2rem;
  }

  .rz-splitbutton .rz-button:not(.rz-splitbutton-menubutton) {
    width: 100%;
  }
}

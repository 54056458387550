@use "Tutor/TutorDetailsPage.razor";
@use "Qualifications/QualificationsDialog.razor";
@use "Qualifications/QualificationsPage.razor";
@use "Schools/SchoolViewPage.razor";
@use "Students/StudentFormPage.razor";
@use "Relationships/TutorStudentRelationshipPage.razor";
@use "Relationships/Organism/RelationshipsIntakeStep.razor";
@use "Admin/AdminPage.razor";
@use "Sessions/SessionsSubmit.razor";
@use "Sessions/Organism/SessionListNavigation.razor";
@use "Timesheet/TimesheetPage.razor";
@use "Timesheet/AdminTimesheetPage.razor";
@use "Accounts/AccountsList.razor";
@use "Calendar/SessionScheduler.razor";
@use "Invoices/InvoicesPage.razor";
@use "TutorAccount/TutorAccountDetails.razor";
@use "Emails/EmailCompose.razor";
@use "Emails/EmailViewPage.razor";
@use "Invoices/InvoiceTemplate.razor";
@use "Emails/EmailPlaceholdersTooltips.razor";
@use "Qualifications/Organism/SubjectModifierManagerDialog.razor";
@use "Dashboard/Dashboard.razor";

.split-button {
  .rz-button:first-child {
    width: 100%;
  }
}

.rz-grid-table .buttons,
.form-footer,
.modal-footer {
  .rz-button + .rz-button {
    margin-left: 0.5rem;
  }
}

.rz-grid-table {
  .buttons {
    text-align: center;
  }
}

.rz-grid-table {
  .rz-datatable-edit {
    .rz-cell-data {
      .rz-textbox,
      .rz-dropdown {
        height: 28px !important;
        padding: 0.2rem !important;
      }
    }
  }
}

.rz-grid-no-overflow {
  .rz-data-grid-data {
    overflow: visible;
    z-index: 1;
  }
}

.rz-fieldset-no-overflow {
  .rz-fieldset-content-wrapper {
    overflow: visible;

    .rz-fieldset-content {
      overflow: visible;
    }
  }
}

.rz-event {
  cursor: pointer;
}

.form-footer {
  display: flex;
  justify-content: center;
  padding-top: 1rem;

  .rz-button {
    width: 160px;
  }
}

.modal-footer {
  text-align: right;
}

.deny-mouse-click {
  pointer-events: none;
}

.tutor-availability-title {
  background-color: var(--rz-success-lighter);
}

.unavailable-availability-title {
  background-color: var(--rz-danger-lighter);
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.required {
  width: 100%;
  display: block;

  &:not(:focus) {
    border-left: 1px solid red;
    border-radius: var(--rz-input-border-radius);
    position: relative;

    &:before {
      content: "Rrequired";
      color: red;
      position: absolute;
      left: 10px;
      z-index: 1;
      top: 25%;
    }
  }

  & > input:focus {
    z-index: 1;
    position: relative;
    background: white;
  }
}

.placeholder-min,
.placeholder-max {
  position: relative;
  display: inline-block;

  &:before {
    color: gray;
    position: absolute;
    left: 10px;
    z-index: 1;
    top: 25%;
  }

  & > input {
    z-index: 2;
    position: relative;
    color: transparent;
    background-color: transparent;
    border-radius: var(--rz-input-border-radius);
    width: calc(100% - 16px);
  }

  & > button {
    z-index: 3;
  }

  & > input:focus {
    color: initial;
    background-color: white !important;
  }
}

.placeholder-min {
  &:before {
    content: "Min";
  }
}

.placeholder-max {
  &:before {
    content: "Max";
  }
}

.selectable-cursor-pointer {
  tbody {
    tr:hover {
      cursor: pointer;
    }
  }
}

.rz-notification {
  top: 72px !important;
  left: calc(50%);
  transform: translateX(-50%);
  width: max-content;
}

.disabled-for-read-only {
  & input:disabled,
  & textarea:disabled,
  & button:disabled {
    color: black !important;
    opacity: 0.8 !important;
  }

  & input:disabled,
  & textarea:disabled {
    background-color: #f3f3f3 !important;
  }

  & button.rz-primary:disabled {
    color: white !important;
    background-color: #afafb2;
  }

  & .rz-state-disabled button.rz-state-disabled {
    opacity: 0.8 !important;
  }
}

.rz-dropdown-list {
  li:first-of-type[aria-label="Remote"]:not(:hover) {
    background-color: aliceblue;
  }
}

ul[role="tablist"] {
  flex-wrap: wrap;
}

.rz-datatable-tfoot {
  tr {
    td {
      border-top: 1px solid #e9eaed !important;
    }
  }
}

.carousel-container {
  display: grid;
  grid-template-columns: repeat(1, 36px 1fr 36px);
  gap: 20px;

  .arrow-container {
    padding-top: 55px;
  }
}

.required-caption:after {
  content: "*";
  color: red;
  margin-top: -5px;
}

.rz-paginator-element {
  padding: 4px !important;
  min-width: 29px !important;
  min-height: 28px !important;
}

:root {
  --rz-accordion-item-padding: 1rem;
  --rz-accordion-item-margin: 0;
  --rz-accordion-item-border: var(--rz-border-base-200);
  --rz-accordion-item-background-color: var(--rz-base-background-color);
  --rz-accordion-item-color: var(--rz-text-secondary-color);
  --rz-accordion-item-line-height: 1.5rem;
  --rz-accordion-item-font-size: var(--rz-body-font-size);
  --rz-accordion-item-font-weight: 500;
  --rz-accordion-icon-width: var(--rz-icon-size);
  --rz-accordion-icon-height: var(--rz-icon-size);
  --rz-accordion-icon-font-size: var(--rz-icon-size);
  --rz-accordion-icon-margin: 0 0.75rem 0 0;
  --rz-accordion-toggle-icon-margin: 0 0 0 0.75rem;
  --rz-accordion-toggle-icon-order: 1;
  --rz-accordion-selected-color: var(--rz-text-title-color);
  --rz-accordion-hover-color: var(--rz-text-title-color);
  --rz-accordion-content-padding: 1rem;
  --rz-accordion-content-font-size: var(--rz-body-font-size);
  --rz-accordion-border-radius: var(--rz-border-radius);
  --rz-accordion-shadow: var(--rz-shadow-1);
}

.rz-accordion {
  box-shadow: var(--rz-accordion-shadow);
}

.rz-accordion-header {
  background-color: var(--rz-accordion-item-background-color);
  margin: var(--rz-accordion-item-margin);
}

.rz-accordion-header:not(:first-child) {
  border-top: var(--rz-accordion-item-border);
}

.rz-accordion-header a[role="tab"] {
  padding: var(--rz-accordion-item-padding);
  color: var(--rz-accordion-item-color);
  line-height: var(--rz-accordion-item-line-height);
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: var(--rz-accordion-item-font-size);
  font-weight: var(--rz-accordion-item-font-weight);
}

.rz-accordion-header a[role="tab"]:hover {
  color: var(--rz-accordion-hover-color);
}

.rz-accordion-header a[role="tab"] span:not(.rz-accordion-toggle-icon) {
  flex-grow: 1;
}

.rz-accordion-header .rzi,
.rz-accordion-header .rz-column-drag,
.rz-accordion-header .rz-datatable-loading-content .rzi-circle-o-notch,
.rz-datatable-loading-content .rz-accordion-header .rzi-circle-o-notch,
.rz-accordion-header .rz-datatable-header .rzi-plus,
.rz-datatable-header .rz-accordion-header .rzi-plus,
.rz-accordion-header .rz-sortable-column .rzi-grid-sort,
.rz-sortable-column .rz-accordion-header .rzi-grid-sort,
.rz-accordion-header .rz-datatable .rzi-chevron-circle-down,
.rz-datatable .rz-accordion-header .rzi-chevron-circle-down,
.rz-accordion-header .rz-datatable .rzi-chevron-circle-right,
.rz-datatable .rz-accordion-header .rzi-chevron-circle-right,
.rz-accordion-header .rz-fileupload-row .rz-button .rzi-close,
.rz-accordion-header .rz-fileupload-row .rz-paginator-element .rzi-close,
.rz-fileupload-row .rz-button .rz-accordion-header .rzi-close,
.rz-fileupload-row .rz-paginator-element .rz-accordion-header .rzi-close,
.rz-accordion-header .rz-fileupload-row .rz-button .rzi-times,
.rz-accordion-header .rz-fileupload-row .rz-paginator-element .rzi-times,
.rz-fileupload-row .rz-button .rz-accordion-header .rzi-times,
.rz-fileupload-row .rz-paginator-element .rz-accordion-header .rzi-times,
.rz-accordion-header .rz-fileupload-row .rz-button .rz-icon-trash,
.rz-accordion-header .rz-fileupload-row .rz-paginator-element .rz-icon-trash,
.rz-fileupload-row .rz-button .rz-accordion-header .rz-icon-trash,
.rz-fileupload-row .rz-paginator-element .rz-accordion-header .rz-icon-trash,
.rz-accordion-header .rz-menuitem .rz-menuitem-icon,
.rz-menuitem .rz-accordion-header .rz-menuitem-icon {
  font-size: var(--rz-accordion-icon-font-size);
  margin: var(--rz-accordion-icon-margin);
}

.rz-accordion-toggle-icon {
  width: var(--rz-accordion-icon-width);
  height: var(--rz-accordion-icon-height);
  font-size: var(--rz-accordion-icon-font-size);
  order: var(--rz-accordion-toggle-icon-order);
}

.rz-accordion-toggle-icon.rzi,
.rz-accordion-toggle-icon.rz-column-drag,
.rz-datatable-loading-content .rz-accordion-toggle-icon.rzi-circle-o-notch,
.rz-datatable-header .rz-accordion-toggle-icon.rzi-plus,
.rz-sortable-column .rz-accordion-toggle-icon.rzi-grid-sort,
.rz-datatable .rz-accordion-toggle-icon.rzi-chevron-circle-down,
.rz-datatable .rz-accordion-toggle-icon.rzi-chevron-circle-right,
.rz-fileupload-row .rz-button .rz-accordion-toggle-icon.rzi-close,
.rz-fileupload-row .rz-paginator-element .rz-accordion-toggle-icon.rzi-close,
.rz-fileupload-row .rz-button .rz-accordion-toggle-icon.rzi-times,
.rz-fileupload-row .rz-paginator-element .rz-accordion-toggle-icon.rzi-times,
.rz-fileupload-row .rz-button .rz-accordion-toggle-icon.rz-icon-trash,
.rz-fileupload-row .rz-paginator-element .rz-accordion-toggle-icon.rz-icon-trash,
.rz-menuitem .rz-accordion-toggle-icon.rz-menuitem-icon {
  margin: var(--rz-accordion-toggle-icon-margin);
}

.rz-accordion-toggle-icon.rzi-chevron-right:before {
  content: "keyboard_arrow_down";
}

.rz-accordion-toggle-icon.rzi-chevron-down:before {
  content: "keyboard_arrow_up";
}

.rz-state-active a[role="tab"] {
  color: var(--rz-accordion-selected-color);
}

.rz-accordion-content-wrapper-overflown {
  overflow: hidden;
}

.rz-accordion-content {
  font-size: var(--rz-accordion-content-font-size);
  background-color: var(--rz-accordion-item-background-color);
  padding: var(--rz-accordion-content-padding);
}

.rz-dropdown-panel,
.rz-multiselect-panel {
  min-width: 150px !important;
}

.rz-dropdown-item,
.rz-multiselect-item span {
  overflow: hidden;
  text-overflow: ellipsis;
}

.w-240 {
  width: 240px;
}

.rzi-chevron-up {
  display: none;
}

.btn-badge-style {
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: inherit;
}

.hr-horizontal {
  border: none;
  background-color: rgba(0, 0, 0, 0.2);
  height: 1px;
  margin: 1rem 0;
}

.hr-vertical {
  border: none;
  background-color: rgba(0, 0, 0, 0.2);
  height: 100%;
  width: 1px;
  margin: 1rem 0;
}

.button-100 {
  .rz-button-box {
    width: 100%;
  }

  .rz-button {
    width: 100%;
  }
}

.rz-button-card {
  display: flex;

  .rz-button-box {
    width: 100%;
  }
}

.rz-pagesize-text-disabled {
  .rz-pagesize-text {
    display: none !important;
  }
}

.text-weight-600 {
  font-weight: 600 !important;
}

.page-nav-btns {
  min-width: 125px;
}

.row-hover {
  /** For hower  **/
  .rz-data-row:hover > td {
    cursor: pointer !important;
    background-color: var(--rz-secondary-lighter) !important;
  }
}

.rz-selectable {
  .rz-data-row:hover > td {
    cursor: pointer !important;
  }
}

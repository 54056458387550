.sessions-submit {
  .sessions-type_submit {
    background-color: var(--rz-success-lighter);
  }
  .sessions-type_unsubmit {
    background-color: var(--rz-warning-lighter);
  }
}

.w-130 {
  width: 130px;
}

.student-form {
  .rz-panel-titlebar {
    align-items: center !important;
  }

  @media (max-width: 1441px) {
    .col-12-laptop {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }
  }
}

@media (max-width: 1550px) and (min-width: 1280px) {
  .dashboard {
    .custom-grid-4cards {
      .rz-col-lg-3 {
        max-width: calc(6 * ((100% - 11 * var(--rz-gap)) / 12) + 5 * var(--rz-gap)) !important;
        flex-basis: calc(6 * ((100% - 11 * var(--rz-gap)) / 12) + 5 * var(--rz-gap)) !important;
      }
    }
  }
}

.dashboard {
  .custom-icon {
    font-size: 48px;
    line-height: 48px;
    width: auto;
    height: auto;
    color: inherit;
  }

  .admin-links {
    .rz-button-card {
      .rz-button-box {
        height: 100%;
      }
    }
  }
}

.custom-fields {
  .rz-accordion-header {
    background-color: rgb(233, 234, 237) !important;
  }
  .rz-accordion-content {
    width: 100% !important;
    padding-top: 0 !important;
    background-color: rgb(233, 234, 237) !important;
  }
  .rz-accordion-header a[role="tab"] {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
}

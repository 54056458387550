.calendar-page {
  .calendar {
    .rz-event {
      cursor: pointer;
      border-radius: 4px;

      .rz-event-content {
        &:hover {
          box-shadow: 0 3px 4px rgba(0, 0, 0, 0.14), 0 3px 3px rgba(0, 0, 0, 0.12), 0 1px 8px rgba(0, 0, 0, 0.14);
        }
      }
    }
  }
}

.tutor-view {
  .rz-events {
    .rz-event {
      top: 1rem !important;
      height: 1rem !important;

      .rz-event-content {
        padding: 0 2px !important;

        div {
          height: 1rem !important;
          line-height: normal !important;
        }
      }
    }

    .rz-event-list-btn {
      top: 2rem !important;
    }
  }
}

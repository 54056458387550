.schoolPhoto {
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    max-width: 156px;
    max-height: 156px;
    min-width: 56px;
  }
}
